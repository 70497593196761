import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'

const ContentRichText = (props) => {
  const { text } = props

  return (RichText.render(text))
}

ContentRichText.propTypes = {
  text: PropTypes.oneOfType([PropTypes.any]).isRequired,
}

export default ContentRichText
